<template>
  <div class="container flex justify-content-center align-items-center p-3">
    <Card class="p-2 shadow-8">
      <template #header>
        <img alt="user header" src="assets/logo_pxcom.png">
      </template>
      <template #title>
        <!-- Login -->
      </template>
      <template #content>
        
        <form class="p-fluid" @keyup.enter="signin">
          <div class="p-field my-5">
            <div class="p-float-label">
              <InputText id="username" v-model="v$.username.$model" :class="{'p-invalid':v$.username.$invalid && submitted}" :disabled="isLoading"/>
              <label for="username" :class="{'p-error':v$.username.$invalid && submitted}">{{ $t('forms.username') }}</label>
            </div>
            <small v-if="(v$.username.$invalid && submitted) || v$.username.$pending.$response" class="p-error">
              {{v$.username.required.$message.replace('Value', 'Username')}}
            </small>
          </div>
          <div class="p-field p-my-5">
            <div class="p-float-label">
              <Password id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" :toggleMask="!isLoading"  :feedback="false" :disabled="isLoading"></Password>
              <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">{{ $t('forms.password') }}</label>
            </div>
            <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{v$.password.required.$message.replace('Value', 'Password')}}</small>
          </div>
        </form>
      </template>
      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('login.forgotPassword')" class="p-button-text p-button-secondary"/>
          <Button :label="$t('login.signIn')" icon="pi pi-sign-in" :loading="isLoading" @click="signin"/>
        </div>
        <Message v-if="hasError" severity="error">{{hasError}}</Message>
        <hr/>
        <div class="text-center comment">
          © 2014 - {{(new Date()).getFullYear()}} PXCom
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Button from "primevue/button"
import Message from "primevue/message"
import Card from "primevue/card"
import InputText from "primevue/inputtext"
import Password from "primevue/password"

import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  components: { Button, Message, Card, InputText, Password },
  setup: () => {
    return { v$: useVuelidate() }
  },
  data: () => ({
    username: '',
    password: '',
    isLoading: false,
    submitted: false,
    hasError: false
  }),
  validations() {
    return {
      username: {
        required
      },
      password: {
        required
      }
    }
  },
  methods: {
    signin () {
      this.submitted = true
      if (this.v$.$invalid) {
        return
      }

      this.isLoading = true

      this.$auth
      .login({username: this.username, password: this.password})
      .then( () => {
        this.hasError = null
        const redirect = this.$route.query.redirect
        if (redirect && redirect.startsWith('/') && redirect !== '/account/login' ) {
          this.$router.push(redirect)
        } else {
          this.$router.push('/')
        }
      })
      .catch( err => {
        this.hasError = err.message
      })
      .finally( () => {
        this.isLoading = false
      })

    }
  }
}
</script>

<style scoped>
.container {
  height: 100vh;
  background-color: rgb(130, 12, 12);
}

.container > * {
  max-width: 400px;
}
</style>